<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Finals
                                    </h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearToFilters" v-model="overallWinnerYearToFilter"
                                        @change="overallWinnerYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearToFilters" v-model="overallWinnerYearToFilter"
                                        @change="overallWinnerYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallCompetitionOptions" v-model="competition"
                                        @change="tournamentUpdated" placeholder="Select" label="Tournament"
                                        item-text="name" return-object outlined hide-details hide-no-data>
                                    </v-select>
                                    <v-combobox v-else :items="overallCompetitionOptions" v-model="competition"
                                        @change="tournamentUpdated" placeholder="Select" label="Tournament"
                                        item-text="name" return-object outlined hide-details hide-no-data>
                                    </v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <div id="overall-table">
                            <v-data-table :headers="activeHeaders" :items="filteredFinals" sort-by="AllIrelandWins"
                                sort-desc class="elevation-1" mobile-breakpoint="0">

                                <template v-slot:item.losingTeam="{ item }">
                                    <span style="display: flex; cursor: pointer;"
                                        @click="goToProfile(item.losingTeam.id)">
                                        <v-img
                                            :src="`/assets/gaa/teams/icons/${item.losingTeam ? item.losingTeam.code : item.awayTeam.code}.WEBP`"
                                            contain max-width="30" class="mr-2 img_border"></v-img>
                                        {{ item.losingTeam ? item.losingTeam.name : item.awayTeam.name }}
                                    </span>
                                </template>

                                <template v-slot:item.date="{ item }">
                                    {{ new Date(item.date).toLocaleDateString() }}
                                </template>

                                <template v-slot:item.score="{ item }">
                                    <div v-if="item.result != 'DRAW'">{{ item.score }}</div>
                                    <div v-else style="color: grey;">Draw {{ item.score }}</div>
                                </template>

                                <template v-slot:item.winningTeam="{ item }">
                                    <span style="display: flex; cursor: pointer;"
                                        @click="goToProfile(item.winningTeam.id)">
                                        <v-img
                                            :src="`/assets/gaa/teams/icons/${item.winningTeam ? item.winningTeam.code : item.homeTeam.code}.WEBP`"
                                            contain max-width="30" class="mr-2 img_border"></v-img>
                                        {{ item.winningTeam ? item.winningTeam.name : item.homeTeam.name }}
                                    </span>
                                </template>

                                <template v-slot:item.highlights="{ item }">
                                    <span v-if="item.highlights" class="cursor-pointer">
                                        <a :href="item.highlights" target="_blank">
                                            <v-icon>mdi-video-vintage</v-icon>
                                        </a>
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    props: ['teamId'],
    name: 'TeamHonours',
    components: {
        StatsCard
    },
    async mounted() {
        let resp = await this.fetchFinalsForSport(this.$router.currentRoute.meta.sport.toUpperCase());
        this.loading = false;
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            competition: 'All Ireland',
            placement: 'All',
            overallTeamSelection: null,
            overallWinnerYearToFilter: 'All',
            overallWinnerYearFromFilter: 'All',
            overallTableHeaders: [
                {
                    text: 'Winner',
                    sortable: false,
                    value: 'winningTeam'
                },
                { text: 'Score', value: 'score', sortable: false },
                {
                    text: 'Opponent',
                    sortable: false,
                    value: 'losingTeam'
                },
                {
                    text: 'Year',
                    sortable: false,
                    value: 'year'
                },
                { text: 'Date', value: 'date', sortable: false },
                { text: 'Tournament', value: 'stage', sortable: false },
                { text: 'Highlights', value: 'highlights', sortable: false },
            ],
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getWinners', 'getAllFinals']),
        activeHeaders() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.overallTableHeaders
            } else {
                return this.overallTableHeaders.filter(it => it.value != 'index' && it.value != 'provincialRunnerUp')
            }
        },
        filteredFinals() {
            let finals = this.getAllFinals;
            if (this.competition != 'All') {
                finals = finals.filter(it => it.stage == this.competition)
            }
            if (this.placement != 'All') {
                if (this.placement == 'Draw') {
                    finals = finals.filter((it) => { return it.result == 'DRAW' })
                } else if (this.placement == 'Champion') {
                    finals = finals.filter((it) => {
                        return (it.homeTeam.id == this.overallTeamSelection.id && it.result == 'HOME_WIN') ||
                            (it.awayTeam.id == this.overallTeamSelection.id && it.result == 'AWAY_WIN');
                    })
                } else {
                    finals = finals.filter((it) => {
                        return (it.homeTeam.id == this.overallTeamSelection.id && it.result == 'AWAY_WIN') ||
                            (it.awayTeam.id == this.overallTeamSelection.id && it.result == 'HOME_WIN');
                    });
                }
            }
            if(this.overallWinnerYearFromFilter && this.overallWinnerYearFromFilter != 'All'){
                finals = finals.filter(it => it.year >= this.overallWinnerYearFromFilter)
            }
            if(this.overallWinnerYearToFilter && this.overallWinnerYearToFilter != 'All'){
                finals = finals.filter(it => it.year <= this.overallWinnerYearToFilter)
            }
            return finals;
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getAllFinals.map(it => it.year).sort((a, b) => b - a).filter(it => it != 'All' && it >= this.overallWinnerYearFromFilter));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getAllFinals.map(it => it.year).sort((a, b) => a - b).filter(it => it != 'All' && it <= this.overallWinnerYearToFilter));
            return years;
        },
        overallCompetitionOptions() {
            let stages = ['All'];
            stages.push(...this.$store.getters['getAllFinals'].map(it => it.stage));
            return stages;
        },
        placementOption() {
            return ['All', 'Champion', 'Runner Up', 'Draw']
        }
    },
    methods: {
        ...mapActions(['fetchWinners', 'updateOverallTeamSelections', 'updateOverallTotalsYearFromFilter', 'fetchFinalsForSport', 'updateOverallWinnerYearFromFilter']),
        goToProfile(id) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        },
        tournamentUpdated() {
            if (this.competition) {
                this.$router.push({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        competition: this.competition
                    }
                });
            }
        },
        overallTeamSelectionChanged() {
            if (this.overallTeamSelection) {
                this.$router.push({
                    name: `Honours_${this.$router.currentRoute.meta.sport}`,
                    params: { mode: 1, teamId: this.overallTeamSelection.id }
                });
            }
        },
        overallWinnerYearFromFilterChanged() {
            this.updateOverallWinnerYearFromFilter(this.overallWinnerYearFromFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    from: this.overallWinnerYearFromFilter
                }
            });
        },
        overallWinnerYearToFilterChanged() {
            //this.updateOverallWinnerYearToFilter(this.overallWinnerYearToFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallWinnerYearToFilter
                }
            });
        },
        calculateScoreText(item) {
            if (item.homeTeam && this.overallTeamSelection && item.homeTeam.id == this.overallTeamSelection.id) {
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.competition) {
                this.competition = this.$router.currentRoute.query.competition;
            }
            if (this.$router.currentRoute.query.from) {
                this.overallWinnerYearFromFilter = isNaN(this.$router.currentRoute.query.from) ? this.$router.currentRoute.query.from : Number(this.$router.currentRoute.query.from);
                //this.overallWinnerYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallWinnerYearToFilter = isNaN(this.$router.currentRoute.query.to) ? this.$router.currentRoute.query.to : Number(this.$router.currentRoute.query.to);
                //this.overallWinnerYearToFilterChanged();
            }
        }
    },
}
</script>

<style></style>